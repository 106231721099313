<template>
  <div class="container">
    <!-- <div class="header-wrap">
      <teacher-header></teacher-header>
    </div> -->
    <!-- <div id="wrapper" v-if="!isLogin" >
      <login />
      <qr-code-list/>
    </div> -->
    <div class="chat-botWrap">
      <div class="chat-wrapper">
        <el-row>
          <el-col :xs="10" :sm="10" :md="8" :lg="8" :xl="7">
            <side-bar />
          </el-col>
          <el-col :xs="14" :sm="14" :md="16" :lg="16" :xl="17">
            <current-conversation />
          </el-col>
        </el-row>
      </div>
      <div class="student-infoWrap">
        <student-info></student-info>
      </div>
    </div>

    <calling ref="callLayer" class="chat-wrapper" />
    <image-previewer />
    <group-live />

    <div class="bg"></div>
  </div>
</template>

<script>
import CurrentConversation from '../../components/IM/conversation/current-conversation';
import SideBar from '../../components/IM/layout/side-bar';
import ImagePreviewer from '../../components/IM/message/image-previewer.vue';
import GroupLive from '../../components/IM/group-live/index';
import Calling from '../../components/IM/message/trtc-calling/calling-index';

import StudentInfo from '@/views/im/studentInfo';

export default {
  name: 'HeadTeacher',
  data() {
    return {
      loginType: 2, // github 登录只使用默认账号登录
    };
  },
  components: {
    StudentInfo,

    SideBar,
    CurrentConversation,
    ImagePreviewer,

    GroupLive,
    Calling,
  },
};
</script>

<style lang="stylus" scoped>



.container
  .header-wrap
    padding 0 0 20px
  position relative
  .chat-botWrap{
    background-color :#fff
    border-radius:4px
    .student-infoWrap{
      margin-left:20px
    }
  }
.container
  position relative
// TODO filter mac chrome 会有问题，下次修改可以去掉
.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: url('~@/./assets/IM/image/bg.jpg') no-repeat 0 0;
  background-size: cover;
  // filter blur(67px)
}

.loading {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-botWrap{
  display:flex
  padding:20px
}
.chat-wrapper {
  width: $width;
  height: $height;
  max-width: 1280px;
  // box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.3);
  // box-shadow :0 1px 5px 0 rgba(0,0,0,0.1)

  .official-link {
    display: flex;
    text-decoration: none;
    color: #38c9ff;
    width: fit-content;
    float: right;
    height: 45px;
    align-items: center;
  }
}
</style>
